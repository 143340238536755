import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon';
import RippleEffect from '../RippleEffect';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';
import { ucFirst } from '../../utils/caseconverters';

const Button = React.forwardRef(
    (
        {
            type = 'primary',
            submit = false,
            href = null,
            onClick = () => {},
            isClicked = false,
            disabled = false,
            icon = null,
            iconDirection = 'right',
            text = null,
            srText = '',
            iconSize = 'medium',
            buttonColor = 'pink',
            iconColor = 'white',
            modifiers = [],
            sizeXLMobile = null,
            name = '',
        },
        ref
    ) => {
        const isLink = href;

        const classes = classNames(
            modifiers.map((x) => x + ' '),
            styles['Button__Container'],
            styles['Button--' + ucFirst(type)],
            styles['Button--' + ucFirst(buttonColor)],
            { [styles['Button--NoIcon']]: !icon },
            { [styles['Button--IsClicked']]: isClicked }
        );

        const innerContent = (
            <>
                <span
                    className={classNames(
                        'Button__Content',
                        styles['Button__Content'],
                        styles['Button__Content--' + ucFirst(buttonColor)],
                        {
                            [styles['Button__Content--TertiaryHover']]:
                                ucFirst(type) === 'Tertiary',
                        }
                    )}>
                    {icon && iconDirection === 'left' && (
                        <span
                            className={classNames(
                                styles['Button__IconContainer'],
                                styles[
                                    'Button__IconContainer--' +
                                        ucFirst(iconDirection)
                                ]
                            )}>
                            <Icon
                                type={icon}
                                size={iconSize}
                                color={iconColor}
                                sizeXLMobile={sizeXLMobile}
                            />
                        </span>
                    )}
                    {icon && iconDirection === 'left' && ' '}
                    {text}
                    {icon && iconDirection === 'right' && ' '}
                    {icon && iconDirection === 'right' && (
                        <span
                            className={classNames(
                                styles['Button__IconContainer'],
                                styles[
                                    'Button__IconContainer--' +
                                        ucFirst(iconDirection)
                                ]
                            )}>
                            <Icon
                                type={icon}
                                size={iconSize}
                                color={iconColor}
                                sizeXLMobile={sizeXLMobile}
                            />
                        </span>
                    )}
                </span>
                <RippleEffect
                    time={10}
                    color={type === 'primary' ? '#c80058' : '#434343'}
                />
            </>
        );

        return (
            <div
                className={
                    styles['Button'] + modifiers.map((x) => ' ' + x).join(' ')
                }>
                {isLink ? (
                    <span className={classes} disabled={disabled}>
                        <a
                            className={styles['Button__Link']}
                            href={href}
                            onClick={onClick}>
                            <span className="sr-only">{srText || text}</span>
                        </a>
                        {innerContent}
                    </span>
                ) : (
                    <button
                        className={classes}
                        disabled={disabled}
                        type={submit ? 'submit' : 'button'}
                        ref={ref}
                        onClick={onClick}>
                        {innerContent}
                        <span className="sr-only">{srText || text}</span>
                    </button>
                )}
            </div>
        );
    }
);

Button.propTypes = {
    type: PropTypes.oneOf([
        'primary',
        'secondary',
        'tertiary',
        'secondaryInvert',
    ]),
    iconDirection: PropTypes.oneOf(['left', 'right']),
    iconSize: PropTypes.oneOf(['small', 'medium', 'large', 'xl']),
    buttonColor: PropTypes.oneOf(['', 'pink', 'white', 'black']),
    href: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    submit: PropTypes.bool,
    icon: PropTypes.string,
    pressed: PropTypes.bool,
    iconColor: PropTypes.string,
    modifiers: PropTypes.array,
    sizeXLMobile: PropTypes.bool,
    name: PropTypes.string,
};

export default Button;
